<template>
  <vs-col vs-w="12">
    <template>
      <template v-if="!hasTwoPartyProducts">
        <vs-card class="transaction">
          <div slot="header" class="flex justify-between items-center">
            <h3 class="font-normal text-sm">New payment request</h3>
          </div>
          <vs-row class="flex-wrap items-end">
            <div class="paycard-wrap flex flex-wrap" v-if="paymentRequestTemplates.length > 0">
              <div v-for="(requestTemplate, key) in paymentRequestTemplates" :key="key" class=" payment-page-cards cursor-pointer" vs-type="flex" vs-justify="center" vs-align="center" v-on:click="selectRequestTemplate(requestTemplate, key)">
                <vs-card class="reqTemplateCard hover:cusror-pointer" :class="{ accessTypeCardActive: paymentRequestTemplates[key].useTheme }">
                  <p class="font-normal text-sm text-center">{{ requestTemplate.templateName }}</p>
                </vs-card>
              </div>
            </div>
            <div class="paycard-wrap flex flex-wrap" v-else>
              <p>Contact your administrator to arrange access to send payment requests</p>
            </div>
            <div class="flex items-end h-full">
              <vs-button class="w-full px-20" v-round @click="$router.push({ name: 'payment-request-create', params: { reqTemId: selectedTemplate._id } })" :disabled="!selectedTemplate">
                <span class="ml-2">Create</span>
              </vs-button>
            </div>
          </vs-row>
        </vs-card>
      </template>
      <template v-else>
        <two-party-product-layout :request-templates="paymentRequestTemplates" :credit-limit="creditLimit" :franchisees="franchisees" />
      </template>
    </template>
  </vs-col>
</template>

<script>
import { mapActions } from "vuex";
import TwoPartyProductLayout from "@/views/components/twoPartyProductLayout";

export default {
  components: {
    TwoPartyProductLayout,
  },

  props: ["creditLimit", "franchisees", "hasTwoPartyProducts", "paymentRequestTemplates"],

  data() {
    return {
      selectedTemplate: "",
    };
  },

  methods: {
    ...mapActions("paymentRequestTemplate", ["fetchAssignedRequestTemplateByMerchantId"]),

    selectRequestTemplate(rt, index) {
      this.paymentRequestTemplates.map((e) => {
        e.useTheme = false;
      });
      this.paymentRequestTemplates[index].useTheme = true;
      this.selectedTemplate = this.paymentRequestTemplates[index];
    },
  },

  mounted() {
    if (this.paymentRequestTemplates.length > 0) {
      this.selectRequestTemplate(null, 0);
    }
  },
};
</script>

