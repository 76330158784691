<template>
  <div class="payment-activity-container w-full">
    <vs-row class="items-start">
      <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
        <vx-card class="custom-vx-card" style="background: #f8f8f8">
          <vs-table :data="paymentrequests" class="stripes no-row-border-table payment-request-table payment-activity-table cursor-default">
            <template slot="thead">
              <vs-th width="14%">
                <label class="m-0 p-0 font-normal">Created</label>
              </vs-th>
              <vs-th width="20%">
                <label class="m-0 p-0 font-normal">Our reference</label>
              </vs-th>
              <vs-th width="20%">
                <label class="m-0 p-0 font-normal">Your reference</label>
              </vs-th>
              <vs-th width="10%" class="custom-center">
                <label class="m-0 p-0 font-normal">Requested</label>
              </vs-th>
              <vs-th width="10%" class="custom-center">
                <label class="m-0 p-0 font-normal">Disbursed</label>
              </vs-th>
              <vs-th width="10%" class="custom-center">
                <label class="m-0 p-0 font-normal">Due</label>
              </vs-th>
              <vs-th width="10%" class="custom-center">
                <label class="m-0 p-0 font-normal">Status</label>
              </vs-th>
              <vs-th width="2%"></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                <vs-td :data="tr.createdAt">
                  {{ moment(tr.createdAt) }}<br />
                  <span class="text-small">{{ momentTime(tr.createdAt) }}</span><br />
                  <span class="text-small">{{ getCreatedByName(tr) }}</span>
                </vs-td>
                <vs-td :data="tr.paymentRequestId">
                  <div>
                    <router-link :to="{ name: 'staff-payment-requests-detail', params: { id: tr._id } }" class="underline cursor-pointer">
                      {{ tr.paymentRequestId }}
                    </router-link><br />
                    <span class="text-small" v-if="!isPayNowComplete(tr)"><p class="text-small">{{ paymentRequestType(tr) }}</p></span>
                    <span class="text-small" v-if="isPayNowComplete(tr)">
                      {{ getUsedPaymentMethod(tr.customerPaymentMethods) }}
                    </span>
                  </div>
                </vs-td>
                <vs-td :data="tr.reference">
                  <span class="text-break my-2">{{ tr.reference }}</span>
                  <p v-if="tr.payeeName" class="my-1 text-small">{{ tr.payeeName }}</p>
                  <p v-if="tr.payeeEmail" class="my-1 text-small">
                    <a v-if="tr.payeeEmail" :href="`mailto:${tr.payeeEmail}`">
                      <u class="text-small">{{ tr.payeeEmail }}</u>
                    </a>
                  </p>
                  <p v-if="tr.payeePhone" class="text-small">{{ tr.payeePhone }}</p>
                </vs-td>
                <vs-td :data="tr.amount" class="flex justify-center text-center">
                  {{ formatCurrency(tr.amount.$numberDecimal || tr.amount) }}<br/>
                  <span v-if="tr.topupAmount > 0" class="text-small">
                    + additional {{ formatCurrency(tr.topupAmount) }}<br/>
                    {{ moment(tr.topups.adminActionedDate) }}
                  </span>
                </vs-td>
                <vs-td :data="tr" class="text-center">
                  <span v-if="tr.hasDisbursedTransactions">{{ formatCurrency(tr.disbursedAmount) }}</span><br />
                  <span v-if="tr.hasDisbursedTransactions" class="text-small">{{ getLastDisbursementDate(tr.transactions || []) }}</span>
                </vs-td>
                <vs-td :data="tr.balances" class="ds-wrapper text-center">
                  <template v-if="showDueAmount(tr)">
                    <span class="mt-2" v-if="tr.balances && tr.balances.balance && tr.balances.balance > 0">{{ formatCurrency(tr.balances.balance) }}</span>
                    <template v-if="tr.paymentStatus == 'Active'">
                      <br />
                      <span class="mb-4 text-small">{{ getLastPaymentDate(tr.transactions || []) }}</span>
                    </template>
                    <template v-if="hasProcessingTransactions(tr.transactions || [])">
                      <br />
                      <vs-chip color="primary" size="small" variant="outlined" class="mt-2">Payment in progress</vs-chip>
                    </template>
                  </template>
                </vs-td>
                <vs-td :data="tr.paymentStatus" class="ds-wrapper text-center">
                  <span v-if="(tr.balances && tr.balances.overdueBalance > 0)" class="badge danger">Overdue</span>
                  <span v-else :class="changeStatusColor(tr.paymentStatus)">{{ tr.paymentStatus }}</span>
                </vs-td>
                <vs-td :data="tr">
                  <div class="dropdown" ref="actionDropdown">
                    <vs-icon icon="more_vert" class="dropbtn" size="small" @click="showDropDown(indextr)" />
                    <div class="dropdown-content" v-if="tr.dropdown">
                      <payment-request-actions
                        :index="indextr"
                        :payment-request="tr"
                        :showDropDown="showDropDown"
                        :getPaymentRequestList="getPaymentRequestList"
                        @requestTopUp="handleRequestFunding"
                        @editDisbursementDate="handleEditDisbursementDate"
                        @editRequest="handleEditRequest"
                      />
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="flex justify-center" v-if="displayShowMore && paymentrequests && paymentrequests.length > 0">
            <a @click="handleShowMore">Show more</a>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>

    <top-up ref="topUpComponent" v-on="$listeners" :product="selectedProduct" :prId="selectedPrId" />
    <edit-disbursement ref="disbursementComponent" v-on="$listeners" :payment-request="selectedPaymentRequest" :prId="selectedPrId" />
    <payment-request-edit ref="editRequestComponent" v-on="$listeners" @update="getPaymentRequestList" :prId="selectedPrId" :paymentRequest="selectedPaymentRequest" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";
import moment from "moment";
import PaymentRequestActions from "./PaymentRequestActions.vue";
import TopUp from "./PaymentRequestDetails/MainDetails/TopUp.vue";
import EditDisbursement from "./PaymentRequestDetails/MainDetails/EditDisbursement.vue";
import PaymentRequestEdit from "./PaymentRequestDetails/MainDetails/PaymentRequestEdit.vue";

export default {
  components: {
    EditDisbursement,
    PaymentRequestActions,
    PaymentRequestEdit,
    TopUp
  },
  name: "PaymentRequest",
  props: ["isFilterApplied", "isSearchApplied", "paymentPlans", "searchParams"],
  data() {
    return {
      showPerPage: 25,
      filtersHidden: true,
      products: [
        "All",
        "Pay now"
      ],
      tabs: [
        { key: "PR", text: "Payment Requests" },
        { key: "TX", text: "Transactions" },
      ],
      selectedPage: "PR",
      paymentrequests: [],
      requestTypes: [
        { text: "All", value: "all" },
        { text: "Pay Now", value: "pay-now" },
        { text: "Pay Later", value: "pay-later" },
      ],
      statusTypes: [
        "All",
        "Active",
        "Cancelled",
        "Completed",
        "Declined",
        "Info-required",
        "Pending",
        "Submitted"
      ],
      loadMore: false,
      displayShowMore: false,
      pageNumber: 1,
      selectedProduct: {},
      selectedPrId: "",
      selectedPaymentRequest: {},
    }
  },
  methods: {
    ...mapActions("paymentRequest", ["exportPaymentRequestWithFilter", "fetchPaymentRequestCountByPartnerIdWithFilter", "fetchPaymentRequestListByPartnerIdWithFilter"]),

    handleRequestFunding(data) {
      this.selectedProduct = data.product;
      this.selectedPrId = data._id;
      this.$refs.topUpComponent.handleTopUpPopUp();
    },

    handleEditDisbursementDate(data) {
      this.selectedPrId = data._id;
      this.selectedPaymentRequest = data.paymentRequest;
      this.$refs.disbursementComponent.handleDisbursementPopUp();
    },

    handleEditRequest(data) {
      this.selectedPaymentRequest = data.paymentRequest;
      this.selectedPrId = data._id;
      this.$refs.editRequestComponent.handlePrEditPopUp();
    },

    moment(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },

    getUsedPaymentMethod(customerPaymentMethods) {
      let paymentMethod = "EFT payment";

      if (customerPaymentMethods && customerPaymentMethods.type == "CARD") {
        paymentMethod = "Card payment";
      } else if (customerPaymentMethods && customerPaymentMethods.type == "DIRECT_DEBIT") {
        paymentMethod = "Direct debit";
      }

      return paymentMethod;
    },

    showDropDown(index) {
      this.paymentrequests = this.paymentrequests.map((item, key) => {
        item.dropdown = (key == index) ? !item.dropdown : false;
        return item;
      });
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },

    momentTime(date) {
      return moment(date).format("h:mm A");
    },

    async getPaymentRequestList(filterUpdated = false, recordCount = false) {
      this.$vs.loading();
      const partnerId = this.partnerId;

      if (filterUpdated) {
        this.pageNumber = 1;
      }

      const data = {
        partnerId: partnerId,
        searchParam: {
          pageNumber: this.pageNumber,
          limit: this.showPerPage,
          totalData: 0,
          ...this.searchParams,
          sortBy: JSON.stringify(this.searchParams.sortBy)
        },
      };

      if (recordCount && this.pageNumber == 1) {
        this.fetchPaymentRequestCountByPartnerIdWithFilter(data).then((result) => {
          this.$emit("updateTotalRecords", result.data.data.totalCount || 0);
        });
      }

      await this.fetchPaymentRequestListByPartnerIdWithFilter(data).then((result) => {
        let paymentRequests = result.data.data;
        this.displayShowMore = (result.data.data.length > this.showPerPage);

        if (paymentRequests.length > this.showPerPage) {
          paymentRequests = paymentRequests.splice(0, this.showPerPage);
        }

        if (this.loadMore) {
          this.paymentrequests = this.paymentrequests.concat(paymentRequests);
        } else {
          this.paymentrequests = paymentRequests;
        }
        this.showDropDown();

        this.loadMore = false;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    showPaymentPopup(pageId) {
      this.selectedPageId = pageId;
      this.paymentRequestPopup = true;
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    paymentRequestType(val) {
      if (["Cancelled", "Declined", "Expired", "Info-required", "Pending"].includes(val.paymentStatus)) {
        if (val.requestOptions.includes("pay-later")) {
          return val.payLaterPlan[0].productName;
        } else {
          return "Pay now";
        }
      } else if (val.requestType == "recurring") {
        return "Recurring";
      } else {
        let returnValue = "";

        if (val.selectedPaymentPlan) {
          returnValue = val.selectedPaymentPlan.currentPlan ? val.selectedPaymentPlan.currentPlan.productName : "";
        }

        return returnValue;
      }
    },

    changeStatusColor(status) {
      let bgClass = "";

      if (["Declined", ].includes(status)) {
        bgClass = "badge danger";
      } else if (["Cancelled", "Expired"].includes(status)) {
        bgClass = "badge text-white bg-secondary-400";
      } else if (status == "Refunded") {
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else if (["Pending"].includes(status)) {
        bgClass = "badge pending";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    handleShowMore() {
      this.pageNumber = this.pageNumber + 1;
      this.loadMore = true;
      this.getPaymentRequestList();
    },

    getCreatedByName(paymentRequest) {
      let createdBy = "";
      let createdByUser = null;
      let isApi = "";

      if (paymentRequest.createdFrom == "Api") {
        isApi = "(API)";
      }

      if (paymentRequest.createdByAdmin && paymentRequest.createdByAdmin.fullName) {
        createdBy = `${paymentRequest.createdUser.fullName} (${process.env.VUE_APP_NAME})`;
      } else {

        if (paymentRequest.createdUser) {
          switch(paymentRequest.createdUser.userType) {
            case "introducerUser":
              createdBy = (paymentRequest.createdUser && paymentRequest.createdUser.introducer && paymentRequest.createdUser.introducer.name) ? paymentRequest.createdUser.introducer.name : "";
              break;
            case "apiUser":
              createdBy = paymentRequest.createdUser.systemName;
              break;
            default:
              createdBy = paymentRequest.createdUser.fullName;
              break;
          }
        }
      }

      return `${createdBy} ${isApi}`;
    },

    getLastPaymentDate(transactions = [], paid = false) {
      if (!transactions || !transactions.length) {
        return;
      }

      const lastTransaction = transactions.filter(item => {
        return (item.type == "Payment" && (paid ? item.status == "Successful" : true));
      });

      if (lastTransaction && lastTransaction.length) {
        return this.moment(lastTransaction[lastTransaction.length - 1].date);
      }

      return;
    },

    getLastDisbursementDate(transactions = []) {
      if (!transactions || !transactions.length) {
        return;
      }

      const lastTransaction = transactions.filter(item => {
        return (item.type == "Disbursement" && item.status == "Disbursed");
      });

      if (lastTransaction && lastTransaction.length) {
        return this.moment(lastTransaction[lastTransaction.length - 1].dateFinalised);
      }

      return;
    },

    hasProcessingTransactions(transactions = []) {
      if (!transactions || !transactions.length) {
        return false;
      }

      return transactions.find(item => item.type == "Payment" && item.status == "Processing");
    },

    showDueAmount(paymentRequest) {
      let showAmount = false;
      const productConfigType = (paymentRequest.selectedProduct && paymentRequest.selectedProduct.productConfigType) ? paymentRequest.selectedProduct.productConfigType : "";

      if (productConfigType == "TWO_PARTY" && paymentRequest.paymentStatus == "Active") {
        showAmount = true;
      } else if (productConfigType == "THREE_PARTY") {
        showAmount = paymentRequest.selectedProduct.showTransactionBalance || false;
      }

      return showAmount;
    },

    handleClickOutside(event) {
      const elements = Array.isArray(this.$refs.actionDropdown) ? this.$refs.actionDropdown : [this.$refs.actionDropdown];
      const isClickInside = elements.some(element => element && element.contains(event.target));

      const targetElement = document.querySelector(".custom-popup-decline");
      const isClickedPopup = targetElement ? targetElement.contains(event.target) : false;

      if (!isClickInside && !isClickedPopup) {
        this.showDropDown();
      }
    },

    isPayNowComplete(val) {
      return (val.paymentStatus == "Completed") && val.requestOptions.includes("pay-now") && (val.requestOptions.length == 1 || !val.selectedPaymentPlan);
    }
  },

  mounted() {
    this.getPaymentRequestList(this.isFilterApplied || this.isSearchApplied, this.isFilterApplied || this.isSearchApplied);

    if (this.paymentPlans && this.paymentPlans.length) {
      const products = this.paymentPlans.map(item => item.productName);
      this.products.push(...products);
    }

    document.addEventListener("click", this.handleClickOutside);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return ["admin", "superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },
  },
};
</script>

