<template>
  <div class="payment-activity-container w-full">
    <vs-row class="items-start">
      <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
        <vx-card class="custom-vx-card" style="background: #f8f8f8">
          <vs-table :data="paymentrequests" class="stripes no-row-border-table payment-request-table payment-activity-table cursor-default">
            <template slot="thead">
              <vs-th width="10%">
                <label class="m-0 p-0 font-normal">Disbursed</label>
              </vs-th>
              <vs-th width="12%">
                <label class="m-0 p-0 font-normal">Our reference</label>
              </vs-th>
              <vs-th width="25%">
                <label class="m-0 p-0 font-normal">Your reference</label>
              </vs-th>
              <vs-th width="18%">
                <label class="m-0 p-0 font-normal">Bank reference</label>
              </vs-th>
              <vs-th width="20%">
                <label class="m-0 p-0 font-normal">Bank account</label>
              </vs-th>
              <vs-th width="5%" class="custom-center">
                <label class="m-0 p-0 font-normal">Amount</label>
              </vs-th>
              <vs-th width="5%" class="custom-center">
                <label class="m-0 p-0 font-normal">Status</label>
              </vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                <vs-td>
                  <span v-if="['Cancelled', 'Pending', 'Failed', 'Processing', 'Queued'].includes(tr.disbursementTransactions.status)">{{ tr.disbursementTransactions.date }}</span>
                  <span v-else>{{ tr.disbursementTransactions.dateFinalised }}</span>
                </vs-td>
                <vs-td>
                  <router-link :to="{ name: 'staff-payment-requests-detail', params: { id: tr._id } }" class="underline cursor-pointer">
                    {{ tr.paymentRequestId }}
                  </router-link><br/>
                  <span class="text-small">{{ getProductName(tr) }}</span>
                </vs-td>
                <vs-td>
                  <span class="text-break">{{ tr.reference }}</span>
                  <p v-if="tr.payeeName" class="text-small">{{ tr.payeeName }}</p>
                  <p v-if="tr.payeeEmail">
                    <a v-if="tr.payeeEmail" :href="`mailto:${tr.payeeEmail}`">
                      <u class="text-small">{{ tr.payeeEmail }}</u>
                    </a>
                  </p>
                  <p v-if="tr.payeePhone" class="text-small">{{ tr.payeePhone }}</p>
                </vs-td>
                <vs-td>
                  <span>{{ (tr.disbursement && tr.disbursement.reference) ? tr.disbursement.reference : "" }}</span><br/>
                  <span class="text-small">{{ getDisbursementMethod(tr) }}</span><br/>
                  <span class="text-small">{{ tr.disbursementTransactions.flipTxId }}</span>
                </vs-td>
                <vs-td v-if="tr.disbursement && tr.disbursement.displayText">
                  {{ tr.disbursement.displayText }}
                </vs-td>
                <vs-td v-else>
                  <span>{{ (tr.disbursement && tr.disbursement.accountName) ? tr.disbursement.accountName : "" }}</span><br/>
                  <span class="text-small" v-if="tr.disbursement && tr.disbursement.bsb">BSB: {{ tr.disbursement.bsb }}</span><br/>
                  <span class="text-small" v-if="tr.disbursement && tr.disbursement.accountNumber">Acc: {{ tr.disbursement.accountNumber }}</span>
                </vs-td>
                <vs-td class="text-center">
                  {{ tr.disbursementTransactions.formattedAmount }}
                </vs-td>
                <vs-td :data="tr.disbursementTransactions.status" class="ds-wrapper">
                  <div class="flex justify-center">
                    <vs-chip :color="changeStatusColor(tr.disbursementTransactions.status)" size="x-small" :variant="getVariant(tr.disbursementTransactions.status)">{{ tr.disbursementTransactions.status }}</vs-chip>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="dropdown" v-if="displayRemittance(tr)" ref="actionDropdown">
                    <vs-icon icon="more_vert" class="dropbtn" size="small" @click="showDropDown(indextr)" />

                    <div class="dropdown-content" v-if="tr.dropdown">
                      <div class="ds-wrapper">
                        <ul class="my-1">
                          <li class="p-2">
                            <a @click="sendRemittance(tr.disbursementTransactions._id, indextr)">Send remittance</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="flex justify-center" v-if="displayShowMore && paymentrequests && paymentrequests.length">
            <a @click="handleShowMore">Show more</a>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Disbursement",
  props: ["isFilterApplied", "isSearchApplied", "searchParams"],
  data() {
    return {
      pageNumber: 1,
      showPerPage: 25,
      paymentrequests: [],
      loadMore: false,
      displayShowMore: false
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchDisbursementCountByPartnerIdWithFilter", "fetchDisbursementListByPartnerIdWithFilter", "sendRemittanceEmail"]),

    async getDisbursementList(filterUpdated = false, recordCount = false) {
      this.$vs.loading();
      const partnerId = this.partnerId;

      if (filterUpdated) {
        this.pageNumber = 1;
      }

      const data = {
        partnerId: partnerId,
        searchParam: {
          pageNumber: this.pageNumber,
          limit: this.showPerPage,
          totalData: 0,
          ...this.searchParams,
          sortBy: JSON.stringify(this.searchParams.sortBy)
        },
      };

      if (recordCount && this.pageNumber == 1) {
        this.fetchDisbursementCountByPartnerIdWithFilter(data).then((result) => {
          this.$emit("updateTotalRecords", result.data.data.totalCount || 0);
        });
      }

      await this.fetchDisbursementListByPartnerIdWithFilter(data).then((result) => {
        let paymentRequests = result.data.data;
        this.displayShowMore = (result.data.data.length > this.showPerPage);

        if (paymentRequests.length > this.showPerPage) {
          paymentRequests = paymentRequests.splice(0, this.showPerPage);
        }

        if (this.loadMore) {
          this.paymentrequests = this.paymentrequests.concat(paymentRequests);
        } else {
          this.paymentrequests = paymentRequests;
        }

        this.showDropDown();
        this.loadMore = false;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    handleShowMore() {
      this.pageNumber += 1;
      this.loadMore = true;
      this.getDisbursementList();
    },

    getProductName(data) {
      return (data.selectedPaymentPlan && data.selectedPaymentPlan.currentPlan) ? data.selectedPaymentPlan.currentPlan.productName : "Pay now";
    },

    getDisbursementMethod(data) {
      return (data.disbursementTransactions.disbursementMethod == "NppCreditBankAccount") ? "Via NPP" : "Via direct credit";
    },

    maskBsb(string) {
      if (!string.includes("-")) {
        let arr = string.replace(" ", "").split("");
        arr.splice(3, 0, "-");
        string = arr.join("");
      }

      return string;
    },

    changeStatusColor(status) {
      let bgClass = "";

      if (["Error"].includes(status)) {
        bgClass = "error";
      } else if (["Disbursed"].includes(status)) {
        bgClass = "success";
      } else if (["Pending"].includes(status)) {
        bgClass = "neutral";
      } else {
        bgClass = "secondary";
      }

      return bgClass;
    },

    getVariant(status) {
      let variant = "";

      if (["Pending", "Queued"].includes(status)) {
        variant = "outlined";
      }

      return variant;
    },

    async sendRemittance(transactionId, index) {
      this.$vs.loading();
      await this.sendRemittanceEmail(transactionId).then(result => {
        this.$vs.notify({
          color: "success",
          title: "Remittance email",
          text: "Remittance email sent successfully.",
        });
      }).catch(ex => {
        this.$vs.notify({
          color: "danger",
          title: "Statement",
          text: "Failed to send remittance email. Please try again later.",
        });
      });

      this.showDropDown(index);
      this.$vs.loading.close();
    },

    displayRemittance(data) {
      return (data.disbursementTransactions && (data.disbursementTransactions.status == "Disbursed") && data.disbursement && !(data.disbursement.isExternal || data.disbursement.displayText));
    },

    showDropDown(index) {
      this.paymentrequests = this.paymentrequests.map((item, key) => {
        item.dropdown = (key == index) ? !item.dropdown : false;
        return item;
      });
    },

    handleClickOutside(event) {
      const elements = Array.isArray(this.$refs.actionDropdown) ? this.$refs.actionDropdown : [this.$refs.actionDropdown];
      const isClickInside = elements.some(element => element && element.contains(event.target));

      const targetElement = document.querySelector(".custom-popup-decline");
      const isClickedPopup = targetElement ? targetElement.contains(event.target) : false;

      if (!isClickInside && !isClickedPopup) {
        this.showDropDown();
      }
    },
  },

  mounted() {
    this.getDisbursementList(this.isFilterApplied || this.isSearchApplied, this.isFilterApplied || this.isSearchApplied);
    document.addEventListener("click", this.handleClickOutside);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return ["admin", "superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },
  },
};
</script>