<template>
  <div id="dashboardPage">
    <vs-row vs-w="12" class="p-0 row-one" v-if="readyToRender">
      <PaymentPageWidgets
        :has-two-party-products="hasTwoPartyProducts"
        :credit-limit="creditLimit"
        :franchisees="franchisees"
        :paymentRequestTemplates="paymentRequestTemplates"
      />
    </vs-row>

    <vs-row vs-w="12" class="w-full mt-2">
      <div class="flex w-full mb-4">
        <div class="w-1/3">
          <label class="ml-3">Filter/Sort:</label>
          <a class="ml-4" @click="showPaymentRequestFilter = true">{{ isFilterApplied ? "Edit" : "Add" }}</a>
          <a v-if="isFilterApplied" class="ml-4" @click="handleClear">Clear</a>
          <label v-if="(isFilterApplied || isSearchApplied) && showRecordsCount" class="ml-8">{{ formatNumber(totalRecords) }} {{ totalRecords == 1 ? "record" : "records" }} found</label>
          <img :src="exportIcon" height="35" alt="export" @click="exportPaymentRequestList" class="cursor-pointer ml-3 align-middle" />
        </div>
        <div class="text-center pt-1" v-if="displayDisbursement">
          <label>View:</label>
          <label :class="`ml-4 ${viewType == 'paymentrequest' ? 'font-bold' : ''}`"><a @click="changeView('paymentrequest')">Requests</a></label>
          <label :class="`ml-4 ${viewType == 'disbursement' ? 'font-bold' : ''}`"><a @click="changeView('disbursement')">Disbursements</a></label>
        </div>
        <div class="flex ml-auto search-box" id="searchContainer">
          <vs-input
            :icon="searchParams.searchText ? 'close' : ''"
            icon-after="true"
            placeholder="Type something to search …"
            class="no-label-input"
            v-model="searchParams.searchText"
            ref="searchInput"
            @keyup.enter="applySearch"
            @keypress="handleSearchTextKeyPress($event)"
          />
          <vs-button size="small" class="search-btn only-border-btn ml-2" v-round @click="applySearch">Go</vs-button>
        </div>
      </div>

      <template v-if="showData">
        <payment-request
          v-if="viewType == 'paymentrequest'"
          :paymentPlans="products"
          ref="paymentRequestComponent"
          :search-params="searchParams"
          :is-filter-applied="isFilterApplied"
          :is-search-applied="isSearchApplied"
          @updateTotalRecords="updateTotalRecords"
          @update="updatePaymentRequestList"
        />
        <disbursement
          v-if="viewType == 'disbursement'"
          ref="disbursementComponent"
          :search-params="searchParams"
          :is-filter-applied="isFilterApplied"
          :is-search-applied="isSearchApplied"
          @updateTotalRecords="updateTotalRecords"
        />
      </template>
    </vs-row>

    <vs-popup title="Filter/Sort" :active.sync="showPaymentRequestFilter" class="filter-popup">
      <div class="flex pb-8">
        <div class="w-3/5">
          <div>
            <label>Date created</label> <br />
            <div class="flex mt-2">
              <date-picker
                v-model="searchParams.fromCreatedDate"
                class="pay-date "
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
              <date-picker
                v-model="searchParams.toCreatedDate"
                class="pay-date ml-4 pr-4"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Date activated</label> <br />
            <div class="flex">
              <date-picker
                v-model="searchParams.fromActivatedDate"
                class="pay-date"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />

              <date-picker
                v-model="searchParams.toActivatedDate"
                class="pay-date ml-4 pr-4"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Date due</label> <br />
            <div class="flex">
              <date-picker
                v-model="searchParams.fromDueDate"
                class="pay-date"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />

              <date-picker
                v-model="searchParams.toDueDate"
                class="pay-date ml-4 pr-4"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Date closed</label> <br />
            <div class="flex">
              <date-picker
                v-model="searchParams.fromClosedDate"
                class="pay-date"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />

              <date-picker
                v-model="searchParams.toClosedDate"
                class="pay-date ml-4 pr-4"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Date disbursed</label> <br />
            <div class="flex">
              <date-picker
                v-model="searchParams.fromDateDisburse"
                class="pay-date"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />

              <date-picker
                v-model="searchParams.toDateDisburse"
                class="pay-date ml-4 pr-4"
                format="DD/MM/YYYY"
                lang="en"
                placeholder="DD/MM/YYYY"
                type="date"
                valueType="format"
                :editable="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Amount requested</label> <br />
            <div class="flex">
              <vs-input
                class="w-1/2"
                type="number"
                v-model="searchParams.fromAmount"
              />
              <vs-input
                class="ml-4 pr-4 w-1/2"
                type="number"
                v-model="searchParams.toAmount"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Current balance</label> <br />
            <div class="flex">
              <vs-input
                class="w-1/2"
                type="number"
                v-model="searchParams.fromBalance"
              />
              <vs-input
                class="w-1/2 ml-4 pr-4"
                type="number"
                v-model="searchParams.toBalance"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Amount overdue</label> <br />
            <div class="flex">
              <vs-input
                class="w-1/2"
                type="number"
                v-model="searchParams.fromOverdueBalance"
              />
              <vs-input
                class="w-1/2 ml-4 pr-4"
                type="number"
                v-model="searchParams.toOverdueBalance"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Disbursed amount</label> <br />
            <div class="flex">
              <vs-input
                class="w-1/2"
                type="number"
                v-model="searchParams.fromDisburseAmount"
              />
              <vs-input
                class="ml-4 pr-4 w-1/2"
                type="number"
                v-model="searchParams.toDisburseAmount"
              />
            </div>
          </div>
          <div class="mt-6">
            <label>Request/agreement status</label> <br />
            <div class="flex">
              <div class="mt-4 w-1/2">
                <template v-for="(status, index) in statusTypes1">
                  <vs-checkbox
                    v-model="searchParams.statusType"
                    name="statusType"
                    v-validate="'required'"
                    :vs-value="status"
                    class="my-2"
                  >{{ status }}</vs-checkbox>
                </template>
              </div>
              <div class="mt-4 pr-4 w-1/2">
                <template v-for="(status, index) in statusTypes2">
                  <vs-checkbox
                    v-model="searchParams.statusType"
                    name="statusType"
                    v-validate="'required'"
                    :vs-value="status"
                    class="my-2"
                  >{{ status }}</vs-checkbox>
                </template>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <label>Disbursement status</label> <br />
            <div class="mt-4">
              <template v-for="(status, index) in disbursementStatus">
                <vs-checkbox
                  v-model="searchParams.disbursementStatus"
                  name="disbursementStatus"
                  v-validate="'required'"
                  :vs-value="status"
                  class="my-2"
                >{{ status }}</vs-checkbox>
              </template>
            </div>
          </div>
          <div class="mt-6">
            <label>Payment/finance options</label> <br />
            <div class="mt-4">
              <vs-checkbox
                v-model="searchParams.product"
                name="financeOption"
                v-validate="'required'"
                :vs-value="'Pay now'"
                class="my-2"
              >
                Pay now
              </vs-checkbox>
              <template v-for="(product, index) in products">
                <vs-checkbox
                  v-model="searchParams.product"
                  name="financeOption"
                  v-validate="'required'"
                  :vs-value="product.productName"
                  class="my-2"
                >
                  {{ product.productName }}
                </vs-checkbox>
              </template>
            </div>
          </div>
          <div class="mt-6">
            <label>Request template</label> <br />
            <div class="mt-4">
              <template v-for="(template, index) in paymentRequestTemplates">
                <vs-checkbox
                  v-model="searchParams.template"
                  name="template"
                  v-validate="'required'"
                  :vs-value="template._id"
                  class="my-2"
                >
                  {{ template.templateName }} {{ (template.paymentOptions && template.paymentOptions[0] && template.paymentOptions[0].planDisplayName) ? `(${template.paymentOptions[0].planDisplayName})` : "" }}
                </vs-checkbox>
              </template>
            </div>
          </div>
          <div class="mt-6">
            <label>Created by</label> <br />
            <div class="mt-4">
              <template v-for="(user, index) in users">
                <vs-checkbox
                  v-model="searchParams.createdBy"
                  name="createdBy"
                  v-validate="'required'"
                  :vs-value="user._id"
                  class="my-2"
                >
                  {{ getCreatedByName(user) }}
                </vs-checkbox>
              </template>
            </div>
          </div>
        </div>
        <div>
          <label>Sort by</label>
          <div class="flex mt-2" v-for="(item, index) in searchParams.sortBy" :key="index">
            <vs-select class="w-2/3" v-model="item.field">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in sortFields"
              />
            </vs-select>
            <vs-select class="w-1/3 ml-1" v-model="item.sortDir">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in sortItems"
              />
            </vs-select>
            <vs-icon
              icon="close"
              size="small"
              class="ml-1 cursor-pointer"
              @click="handleClearSort(index)"
            />
          </div>
        </div>
      </div>

      <div class="popup-action-btn-container pb-8 pt-6 sticky bottom-0 bg-white">
        <div class="popup-action-btn pt-8 flex justify-end">
          <vs-button size="large" action-btn @click="applyFilter">Apply</vs-button>
          <vs-button @click="clearSearch" size="large" type="flat" class="ml-6 md:ml-10">Clear</vs-button>
          <vs-button @click="handleCancel" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapActions } from "vuex";
  import PaymentPageWidgets from "./widgets/PaymentPageWidgets.vue";
  import PaymentRequest from "@/views/pages/partners/paymentRequest/paymentRequest.vue";
  import Disbursement from "@/views/pages/partners/paymentRequest/Disbursement.vue";
  import DatePicker from "vue2-datepicker";

  export default {
    components: {
      DatePicker,
      Disbursement,
      PaymentPageWidgets,
      PaymentRequest,
    },

    data() {
      return {
        creditLimit: 0,
        franchisees: [],
        hasTwoPartyProducts: false,
        products: [],
        paymentRequestTemplates: [],
        showData: false,
        displayDisbursement: false,
        viewType: "paymentrequest",
        showPaymentRequestFilter: false,
        statusTypes1: [
          "Pending",
          "Submitted",
          "Active",
          "Completed",
        ],
        statusTypes2: [
          "Info-required",
          "Declined",
          "Cancelled",
          "Expired",
        ],
        disbursementStatus: [
          "Disbursed",
          "Pending",
          "Processing",
        ],
        searchParams: {
          fromCreatedDate: "",
          toCreatedDate: "",
          fromActivatedDate: "",
          toActivatedDate: "",
          fromClosedDate: "",
          toClosedDate: "",
          fromAmount: "",
          toAmount: "",
          fromBalance: "",
          toBalance: "",
          fromDisburseAmount: "",
          toDisburseAmount: "",
          fromOverdueBalance: "",
          toOverdueBalance: "",
          fromDateDisburse: "",
          toDateDisburse: "",
          fromDueDate: "",
          toDueDate: "",
          statusType: [],
          product: [],
          template: [],
          createdBy: [],
          disbursementStatus: [],
          searchText: "",
          sortBy: [
            {
              field: "",
              sortDir: 1
            },
            {
              field: "",
              sortDir: 1
            },
            {
              field: "",
              sortDir: 1
            },
            {
              field: "",
              sortDir: 1
            },
            {
              field: "",
              sortDir: 1
            }
          ],
        },
        isFilterApplied: false,
        isSearchApplied: false,
        readyToRender: false,
        users: [],
        totalRecords: 0,
        showRecordsCount: false,
        exportIcon: require("@/assets/images/excel.png"),
        sortFields: [
          {
            label: "Date created",
            value: "createdAt",
          },
          {
            label: "Date activated",
            value: "openingDate",
          },
          {
            label: "Date due",
            value: "dueDate.convertedDate",
          },
          {
            label: "Date closed",
            value: "closingDate",
          },
          {
            label: "Date disbursed",
            value: "transactions.convertedDateFinalised",
          },
          {
            label: "Amount requested",
            value: "filterAmount",
          },
          {
            label: "Current balance",
            value: "balances.balance",
          },
          {
            label: "Amount overdue",
            value: "balances.overdueBalance",
          },
          {
            label: "Disbursed amount",
            value: "disbursedAmount",
          },
          {
            label: "Request/agreement status",
            value: "paymentStatus",
          },
          {
            label: "Disbursement status",
            value: "disbursementTransactions.status",
          },
          {
            label: "Payment/finance option",
            value: "payLaterPlanDocument",
          },
          {
            label: "Your reference",
            value: "reference",
          },
        ],
        sortItems: [
          {
            label: "ascending",
            value: 1
          },
          {
            label: "descending",
            value: -1
          },
        ]
      }
    },

    methods: {
      ...mapActions("admin", ["checkLogin"]),
      ...mapActions("paymentRequest", ["exportPaymentRequestWithFilter"]),
      ...mapActions("paymentRequestTemplate", ["fetchAssignedRequestTemplateByMerchantId"]),
      ...mapActions("partner", ["fetchAllUsers"]),

      handleClearSort(index) {
        this.searchParams.sortBy[index].field = "";
        this.searchParams.sortBy[index].sortDir = "";
      },

      handleSearchTextKeyPress(event) {
        if (event.key === "\\") {
          event.preventDefault();
        }
      },

      getCreatedByName(user) {
        let name = user.fullName;

        if (user.userType == "introducerUser") {
          name = user.introducerId.platformName || user.introducerId.name;
        } else if (user.userType == "apiUser") {
          name = user.systemName;
        }

        return name;
      },

      async getPaymentRequestTemplates(partnerId) {
        await this.fetchAssignedRequestTemplateByMerchantId({ merchantId: partnerId, type: "template" }).then((result) => {
          this.paymentRequestTemplates = result.data.data;

          this.paymentRequestTemplates.map((element) => {
            element.useTheme = false;
          });
        });

        this.paymentRequestTemplates = this.paymentRequestTemplates.filter(template => ["advance", "simple"].includes(template.templateType));
        this.readyToRender = true;
      },

      changeView(value) {
        this.viewType = value;
        this.totalRecords = 0;
        this.showRecordsCount = false;
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup", "filter-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup", "filter-popup");
        }
      },

      handleCancel() {
        this.showPaymentRequestFilter = false;
      },

      async handleClear() {
        await this.clearSearch();

        if (this.viewType == "paymentrequest") {
          this.$refs.paymentRequestComponent.getPaymentRequestList(true, this.isSearchApplied);
        } else {
          this.$refs.disbursementComponent.getDisbursementList(true, this.isSearchApplied);
        }

        this.totalRecords = 0;
        this.showRecordsCount = false;
      },

      async clearSearch() {
        this.searchParams = {
          fromCreatedDate: "",
          toCreatedDate: "",
          fromActivatedDate: "",
          toActivatedDate: "",
          fromClosedDate: "",
          toClosedDate: "",
          fromAmount: "",
          toAmount: "",
          fromBalance: "",
          toBalance: "",
          fromDisburseAmount: "",
          toDisburseAmount: "",
          fromOverdueBalance: "",
          toOverdueBalance: "",
          fromDateDisburse: "",
          toDateDisburse: "",
          fromDueDate: "",
          toDueDate: "",
          statusType: [],
          product: [],
          template: [],
          createdBy: [],
          disbursementStatus: [],
          searchText: this.searchParams.searchText,
          sortBy: [
            {
              field: "",
              sortDir: ""
            },
            {
              field: "",
              sortDir: ""
            },
            {
              field: "",
              sortDir: ""
            },
            {
              field: "",
              sortDir: ""
            },
            {
              field: "",
              sortDir: ""
            }
          ],
        };

        this.isFilterApplied = false;
        this.isSearchApplied = this.searchParams.searchText ? true : false;
      },

      applyFilter() {
        this.showPaymentRequestFilter = false;
        this.isFilterApplied = Object.values(this.searchParams).some(value => Boolean(typeof value == "object" ? value.length : value));
        this.showRecordsCount = false;
        // call API
        if (this.viewType == "paymentrequest") {
          this.$refs.paymentRequestComponent.getPaymentRequestList(true, true);
        } else {
          this.$refs.disbursementComponent.getDisbursementList(true, true);
        }
      },

      applySearch() {
        this.isSearchApplied = this.searchParams.searchText ? true : false;
        this.showRecordsCount = false;

        if (this.viewType == "paymentrequest") {
          this.$refs.paymentRequestComponent.getPaymentRequestList(true, true);
        } else {
          this.$refs.disbursementComponent.getDisbursementList(true, true);
        }
      },

      async fetchUsers() {
        await this.fetchAllUsers(this.partnerId).then(result => {
          this.users = result.data.data || [];
        });
      },

      updateTotalRecords(recordCount) {
        this.totalRecords = recordCount;
        this.showRecordsCount = true;
      },

      async exportPaymentRequestList() {
        this.$vs.loading();
        const data = {
          partnerId: this.partnerId,
          searchParam: {
            ...this.searchParams,
            sortBy: JSON.stringify(this.searchParams.sortBy)
          },
          type: this.viewType
        };

        await this.exportPaymentRequestWithFilter(data).then(() => {
          this.$vs.loading.close();
        }).catch(ex => {
          this.showMessage("Error", "Unable to export payment requests", "danger", "icon-times-circle");
          this.$vs.loading.close();
        });
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },

      searchClear() {
        this.searchParams.searchText = "";
        this.applySearch();
      },

      updatePaymentRequestList() {
        this.$refs.paymentRequestComponent.getPaymentRequestList(true, this.isSearchApplied);
      }
    },

    watch: {
      showPaymentRequestFilter(val) {
        this.addPopUpClass(val);
      },
    },

    beforeMount() {
      this.checkLogin().then(result => {
        this.showData = true;
        this.products = result.data.data.plans;
        this.hasTwoPartyProducts = this.products.some(item => item.productConfigType === "TWO_PARTY");
        this.creditLimit = result.data.data.creditLimit;
        this.franchisees = result.data.data.franchisees && result.data.data.franchisees.length ? result.data.data.franchisees : [];
        this.franchisees = this.franchisees.map(item => {
          return {
            companyName: item.companyName,
            products: item.products.map(obj => {
              return {
                productDisplayName: obj.productDisplayName,
                value: { customPlanId: obj.customPlanId, partnerId: item.partnerId }
              }
            })
          }
        });
        this.displayDisbursement = result.data.data.viewDisbursement;
      });
      this.getPaymentRequestTemplates(this.partnerId);
      this.fetchUsers(this.partnerId);
    },

    mounted() {
      let self = this;
      document.getElementById("searchContainer").addEventListener("click", function(event) {
        if (event.target.classList.contains("icon-after")) {
          self.searchClear();
        }
      });

      this.$refs.searchInput.focusInput();
    },

    computed: {
      user() {
        return this.$store.state.AppActiveUser;
      },

      partnerId() {
        return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
      },
    },
  };
</script>
<style>
  .filter-popup .vs-popup--content {
    padding-bottom: 0px !important;
  }

  .filter-popup .popup-action-btn {
    border-top: 1px solid lightgray !important;
  }
</style>

