<template>
  <div>
    <vs-row vs-w="12">
      <vs-col class="md:pr-4" vs-w="3" vs-sm="12">
        <vs-card class="ds-wrapper bg-white product-wrapper">
          <div slot="header" class="flex justify-between items-center">
            <label class="font-normal text-sm">New payment request</label>
          </div>
          <div class="mt-6 input-container">
            <vs-select v-model="requestTemplateId" name="requestTemplateId" placeholder="Select a request template" class="vs-select--input">
              <vs-select-item v-for="(template, key) in requestTemplates" :key="key" :value="template._id" :text="template.templateName"></vs-select-item>
            </vs-select>
          </div>
          <div class="text-center">
            <vs-button color="primary" size="large" class="w-3/4" :disabled="!requestTemplateId" @click="handleCreate">Create</vs-button>
          </div>
        </vs-card>
      </vs-col>
      <vs-col v-if="hasFranchisee" class="md:pr-4" vs-w="3" vs-sm="12">
        <vs-card class="ds-wrapper bg-white product-wrapper">
          <div slot="header" class="flex justify-between items-center">
            <label class="font-normal text-sm">Linked accounts</label>
          </div>
          <div class="mt-6 input-container">
            <vs-select v-model="franchiseeProduct" placeholder="Select linked account">
              <div :key="index" v-for="(franchisee, index) in franchisees">
                <vs-select-group :title="franchisee.companyName" v-if="franchisee.products">
                  <vs-select-item :key="index" :value="item.value" :text="item.productDisplayName" v-for="(item, index) in franchisee.products" class="product-group" />
                </vs-select-group>
              </div>
            </vs-select>
          </div>
          <div class="text-center">
            <vs-button color="primary" size="large" class="w-3/4" :disabled="!franchiseeProduct" @click="handleFranchiseePrCreate">Create</vs-button>
          </div>
        </vs-card>
      </vs-col>
      <vs-col class="mb-1" :vs-w="hasFranchisee ? 6 : 9" vs-sm="12">
        <div class="payment-activity-container w-full">
          <vs-row class="items-start">
            <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
              <vx-card class="payment-vx-card">
                <vs-table class="pay-activity-table stripes no-row-border-table two-party-table" noDataText="">
                  <template slot="thead">
                    <vs-th width="15%">
                      <label class="m-0 p-0 font-normal">Your funding</label>
                    </vs-th>
                    <template v-if="productsCalculation">
                      <vs-th v-for="(product, index) in productsCalculation.products" :key="index" class="product-header">
                        <label class="m-0 p-0 font-normal">{{ product.productName }}</label>
                      </vs-th>
                      <vs-th class="product-header">
                        <label class="m-0 p-0 font-normal">Total</label>
                      </vs-th>
                    </template>
                    <vs-th v-if="creditLimit">
                      <label class="m-0 p-0 font-normal">Credit limit</label>
                    </vs-th>
                  </template>
                  <template>
                    <vs-tr>
                      <vs-td>Active/open</vs-td>
                      <template v-if="productsCalculation">
                        <vs-td v-for="(product, index) in productsCalculation.products" :key="index + 0.1" class="product-body">{{ product.totalPaymentRequest }}</vs-td>
                        <vs-td class="product-body">{{ productsCalculation.totalPaymentRequests }}</vs-td>
                      </template>
                      <vs-td>
                      </vs-td>
                    </vs-tr>
                    <vs-tr>
                      <vs-td>Balance</vs-td>
                      <template v-if="productsCalculation">
                        <vs-td v-for="(product, index) in productsCalculation.products" :key="index + 0.1" class="product-body">$ {{ addZeroes(product.totalAmount.$numberDecimal || product.totalAmount) }}</vs-td>
                        <vs-td class="product-body">$ {{ addZeroes(productsCalculation.totalAmount.$numberDecimal || productsCalculation.totalAmount) }}</vs-td>
                      </template>
                      <vs-td v-if="creditLimit">$ {{ addZeroes(creditLimit) }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: ["creditLimit", "franchisees", "requestTemplates"],

    data() {
      return {
        franchiseeProduct: null,
        productsCalculation: null,
        requestTemplateId: "",
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["getPaymentRequestGroupedTotalAmount"]),

      handleCreate() {
        if (!this.requestTemplateId) {
          return;
        }

        this.$router.push({ name: "payment-request-create", params: { reqTemId: this.requestTemplateId } });
      },

      handleFranchiseePrCreate() {
        if (!this.franchiseeProduct) {
          return;
        }

        this.$router.push({ name: "franchisee-payment-request-create", params: this.franchiseeProduct });
      },

      async fetchPaymentRequestTotalAmount(merchantId) {
        await this.getPaymentRequestGroupedTotalAmount(merchantId).then(result => {
          this.productsCalculation = result.data.data || null;
        });
      },

      addZeroes(num) {
        num = Math.trunc(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 0,
        });
      },
    },

    created() {
      if (this.requestTemplates.length === 1) {
        this.requestTemplateId = this.requestTemplates[0]._id;
      }

      this.fetchPaymentRequestTotalAmount(this.partnerId);
    },

    computed: {
      user() {
        return this.$store.state.AppActiveUser;
      },

      partnerId() {
        return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
      },

      hasFranchisee() {
        return (this.franchisees && this.franchisees.length);
      }
    },
  }
</script>

